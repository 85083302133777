import { createSelector } from 'reselect';
export const getDocumentsData = (state) => state.documents;

export const getDocument = createSelector([getDocumentsData], (documents) => documents.document);
export const getDocumentInvite = createSelector([getDocumentsData], (documents) => documents.invite);
export const getDocumentClient = createSelector([getDocumentsData], (documents) => documents.client);
export const getDocumentSmsToken = createSelector([getDocumentsData], (documents) => documents.smsToken);
export const getDocumentIscompleted = createSelector([getDocumentsData], (documents) => documents.isCompleted);
export const getDocumentError = createSelector([getDocumentsData], (documents) => documents.errorMessage);
export const getIsDocumentLoggedIn = createSelector([getDocumentsData], (documents) => documents.isLoggedIn);
